import React, { Component } from "react"
import { Link } from "gatsby"
import "@components/form_parent_registration/style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/pledge"
import { validate } from "@data/validations"

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: null,
      newpassword: "",
      confirmpassword: "",
      newPasswordErrorStatus: "",
      confPasswordErrorStatus: "",
      buttonSubmitted: false,
      generalError: null,
      passwordChangeSuccess: false
    }
  }

  componentDidMount = () => {
    let urlParams = new URLSearchParams(window.location.search),
      token = urlParams.get(`token`)
    if (token) this.setState({ token })
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let newPasswordErrorStatus = !validate.password.test(
        this.state.newpassword
      ),
      confPasswordErrorStatus =
        this.state.newpassword !== this.state.confirmpassword
    this.setState({
      newPasswordErrorStatus,
      confPasswordErrorStatus
    })
    if (!newPasswordErrorStatus && !confPasswordErrorStatus) {
      this.setState({ buttonSubmitted: true })

      let data = {
        password_reset_token: this.state.token,
        password: this.state.newpassword,
        password_confirmation: this.state.confirmpassword
      }

      const options = {
        method: "PATCH",
        data: JSON.stringify(data),
        headers: headers.getHeaders(),
        url: `${process.env.API_HOST}v1/users/password_reset`
      }
      axios(options)
        .then(res => {
          console.log(res)
          this.setState({
            buttonSubmitted: false,
            passwordChangeSuccess: true,
            newpassword: "",
            confirmpassword: ""
          })
          this.props.handler()
        })
        .catch(error => {
          console.log(error.response)
          this.setState({
            buttonSubmitted: false,
            generalError: "Please Try Again"
          })
        })
    }
  }

  errorHandler = err => {
    this.setState({ buttonSubmitted: false })
    console.log(err)
    alert("There was an error, check your network connectivity and try again")
  }

  render() {
    const { parentReg } = data
    return (
      <div className="registration-form">
        {this.state.passwordChangeSuccess ? (
          <Link
            to="/login"
            style={{
              textAlign: `center`,
              color: `#00b983`,
              display: `block`,
              lineHeight: 1.5
            }}
          >
            Please log in
          </Link>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <InputLabelState
              data={{
                name: `New Password`,
                type: `password`,
                helpText:
                  "Min 8 characters, 1 uppercase, 1 lowercase, 1 number",
                errorStatus: this.state.newPasswordErrorStatus,
                defaultVisibility: 1,
                value: this.state.newpassword
              }}
              onChange={this.handleChange.bind(this)}
            />
            <InputLabelState
              data={{
                name: `Confirm Password`,
                type: `password`,
                helpText: "Passwords must match",
                errorStatus: this.state.confPasswordErrorStatus,
                defaultVisibility: 0,
                value: this.state.confirmpassword
              }}
              onChange={this.handleChange.bind(this)}
            />
            <div style={{ width: `100%`, height: `3rem` }}></div>
            <GeneralSubmitMessage error={this.state.generalError} />
            <FormButton
              data={parentReg.regButton}
              isDisabled={this.state.buttonSubmitted}
            />
          </form>
        )}
      </div>
    )
  }
}

export default ResetPasswordForm
