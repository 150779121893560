import React, { Component } from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import HeaderandText from "@components/header_with_text"
import ResponseMessage from "@components/_atoms/response_message"
import Layout from "@components/layout"
import ResetPasswordForm from "@components/_molecules/form_reset_password"

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: false
    }
  }
  stateHandler() {
    this.setState({ success: true })
  }
  render() {
    const { resetPassword } = data
    return (
      <Layout type="light" pageClass="pledge">
        <div style={{ height: `48px`, width: `100%`, display: `block` }}></div>
        <SEO title={resetPassword.title} />
        {this.state.success && (
          <ResponseMessage>Successfully updated password</ResponseMessage>
        )}
        <HeaderandText data={resetPassword.heading} />
        <ResetPasswordForm handler={this.stateHandler.bind(this)} />
      </Layout>
    )
  }
}

export default LoginPage
